import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import useStyles from './style'

const BaseButton = ({
    className,
    children,
    variant,
    disabled,
    color,
    size,
    defaultPadding,
    loading,
    disabledText,
    ...props
}) => {
    const classes = useStyles({
        variant, disabled, color, size, defaultPadding
    })

    return (
        <Button
            className={props?.style ? '' : clsx(classes.buttonbb, className)}
            variant={variant}
            disabled={disabled || loading}
            color={color}
            size={size}
            disableElevation
            {...props}
        >
            {loading && (
                <div className={classes.loadingSpinnerbb}>
                    <CircularProgress size={30} />
                </div>
            )}
            { disabled && disabledText ? disabledText : children }
        </Button>
    )
}

BaseButton.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.string,
    defaultPadding: PropTypes.bool,
    loading: PropTypes.bool,
    disabledText: PropTypes.string
}
BaseButton.defaultProps = {
    className: null,
    children: undefined,
    variant: 'contained',
    disabled: false,
    color: 'primary',
    size: 'medium',
    defaultPadding: false,
    loading: false,
    disabledText: undefined
}
export default BaseButton
