import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    inputButtonbg: {
        display: 'flex'
    },
    inputbg: {
        backgroundColor: '#fff',
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    buttonbg: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        height: '36px'
    }
}))
