import { InputBase } from '@material-ui/core'
import { string, func, bool } from 'prop-types'
import useStyles from './style'
import theme from '../../../../lib/theme'
import BaseButton from '../Button/BaseButton'

const InputButtonGroup = ({
    error,
    inputName,
    inputType,
    buttonType,
    buttonLabel,
    onClick,
    onChange,
    loading
}) => {
    const classes = useStyles()
    const handleKeyDown = ({ key }) => {
        if (key === 'Enter' && onClick) {
            onClick()
        }
    }

    return (
        <div style={{display: 'flex'}}>
            <InputBase
                error={error}
                name={inputName}
                type={inputType}
                
                onChange={onChange}
                onKeyDown={handleKeyDown}
                fullWidth
                style={{backgroundColor: '#fff',
                    borderBottomLeftRadius: 4,
                    borderTopLeftRadius: 4,
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1)}}
            />

            <BaseButton
                type={buttonType}
                style={{
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                    height: '36px',
                    width: '50%'
                }}
                onClick={onClick}
                loading={loading}
            >
                {buttonLabel}
            </BaseButton>
        </div>
    )
}

InputButtonGroup.propTypes = {
    inputName: string,
    inputType: string,
    buttonType: string,
    buttonLabel: string,
    onClick: func,
    onChange: func,
    loading: bool,
    error: bool
}

InputButtonGroup.defaultProps = {
    inputName: undefined,
    inputType: 'text',
    buttonType: 'button',
    buttonLabel: 'button',
    onClick: undefined,
    onChange: undefined,
    loading: undefined,
    error: false
}

export default InputButtonGroup
